/* Default casdoor styles */

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

.logo {
  height: 27px;
  margin: 17px 0 16px 15px;
  float: left;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle thead > tr > th,
.ant-table.ant-table-middle tbody > tr > td {
  padding: 1px 8px !important;
}

.ant-list-sm .ant-list-item {
  padding: 2px !important;
}

.ant-drawer-body {
  padding: 0 !important;
  overflow: hidden !important;
}

.custom-link:hover {
  color: rgb(64 64 64) !important;
}

.login-forget-password {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.login-button {
  width: 100%;
}

.provider-img {
  width: 30px;
  margin: 5px;
}

.provider-big-img {
  margin-bottom: 10px;
}

.or-section {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 24px;

  div {
    width: 100%;
    height: 2px;
    background: var(--stroke-100);
  }

  span {
    text-align: center;
    font-weight: 500;
    line-height: 20px;
    color: var(--text-400);
  }
}

.full-height-editor {
  height: 100%;
}

.full-height-editor [class*="CodeMirror"] {
  height: 100%;
}

.no-horizontal-scroll-editor [class*="CodeMirror-hscrollbar"] {
  display: none !important;
}

.no-horizontal-scroll-editor [class*="CodeMirror-scroll"] {
  overflow-x: hidden !important;
}
