@import "./tailwind.css";
@import "./casdoor.css";
@import "https://fonts.googleapis.com/css2?family=Heebo:wght@400;420;450;500;600;700&display=swap";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Heebo, sans-serif;
}

body {
  font-size: clamp(0.875rem, 1.5vw, 1rem);
}

p {
  font-family: Heebo, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: Manrope, sans-serif;
}

h1 {
  font-size: 2rem;
  line-height: 1.3;
}

h2 {
  font-size: 1.5rem;
  line-height: 1.4;
}

h3 {
  font-size: 1.25rem;
  line-height: 1.4;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.45;
}

h5 {
  font-size: 1rem;
  line-height: 1.5;
}

a {
  color: #1a63f4 !important;
}

:root {
  --primary-100: #f2ebf9;
  --primary-200: #ddccef;

  /* default */
  --primary-300: #5301b1;

  /* hover */
  --primary-400: #4b01a0;
  --primary-500: #3e0185;
  --primary-600: #32016a;
  --background-100: #fff;
  --background-200: #faf9fb;
  --background-300: #f5f4f6;
  --background-400: #edebee;
  --stroke-100: #e5e4e7;
  --stroke-200: #cbc9cf;
  --text-100: #fff;
  --text-200: #99949e;
  --text-300: #66616b;
  --text-400: #4d4950;
  --text-500: #333036;
  --text-600: #1a181b;
  --link-100: #ebf5ff;
  --link-200: #cce4ff;
  --link-300: #007aff;
  --link-400: #0062cc;
  --link-500: #0062cc;
  --link-600: #004999;
  --success-100: #ecfaf0;
  --success-200: #cff2d8;
  --success-300: #10bc3b;
  --success-400: #0eaa35;
  --success-500: #0d962f;
  --success-600: #0a7123;
  --error-100: #ffefef;
  --error-200: #ffd6d6;
  --error-300: #f33;
  --error-400: #e62e2e;
  --error-500: #cc2929;
  --error-600: #991f1f;
  --warning-100: #fff7eb;
  --warning-200: #ffeacc;
  --warning-300: #ff9500;
  --warning-400: #e68600;
  --warning-500: #c70;
  --warning-600: #995900;
  --components-tooltip-background: #141216;
  --components-tooltip-bodyText: #ccc9cf;
  --components-avatar-user: #bfbcc2;
  --components-callState-background: #fff;
  --components-callState-recordBar: #d9d5dd;
  --components-tabs-background: #f5f4f6;
  --components-tabs-active: #fff;
  --components-leftBar-background: #000;
  --components-modal-background: #fff;
  --components-dropdown-background: #fff;
  --components-dropdown-hover: #faf9fb;
  --components-dropdown-active: #f5f4f6;
  --tags-blue: #6d8def;
  --tags-orchid: #aa6def;
  --tags-magenta: #ef6dc2;
  --tags-lilac: #e247f0;
  --tags-vermillion: #ef6d6d;
  --tags-grape: #7a6def;
  --tags-pacific: #6dc2ef;
  --tags-lime: #b0ef6d;
  --tags-pear: #6defa5;
  --tags-cyan: #6defe4;
  --tags-mustard: #f7e56e;
  --tags-amber: #efb36d;
  --grey-50: #f3f2f4;
}

.dark:root {
  --primary-100: #1d003d;
  --primary-200: #300166;
  --primary-300: #5f01cb;
  --primary-400: #7727d3;
  --primary-500: #8f4dda;
  --primary-600: #bf99ea;
  --background-100: #141216;
  --background-200: #19171c;
  --background-300: #1f1c22;
  --background-400: #242027;
  --stroke-100: #26232a;
  --stroke-200: #333036;
  --text-100: #fff;
  --text-200: #66616b;
  --text-300: #b2afb6;
  --text-400: #ccc9cf;
  --text-500: #e5e4e7;
  --text-600: #f3f2f3;
  --link-100: #08294d;
  --link-200: #0d4480;
  --link-300: #1987ff;
  --link-400: #0062cc;
  --link-500: #5eabff;
  --link-600: #a3cfff;
  --success-100: #054014;
  --success-200: #096a21;
  --success-300: #12d342;
  --success-400: #35da5e;
  --success-500: #59e07a;
  --success-600: #a0edb3;
  --error-100: #4d1717;
  --error-200: #802626;
  --error-300: #ff4c4c;
  --error-400: #ff6767;
  --error-500: #ff8181;
  --error-600: #ffb7b7;
  --warning-100: #4d3008;
  --warning-200: #80500d;
  --warning-300: #ffa019;
  --warning-400: #ffae3b;
  --warning-500: #ffbc5e;
  --warning-600: #ffd9a3;
  --components-tooltip-background: #232026;
  --components-tooltip-bodyText: #ccc9cf;
  --components-avatar-user: #7a7580;
  --components-callState-background: #2e2932;
  --components-callState-recordBar: #141316;
  --components-tabs-background: #0a090b;
  --components-tabs-active: #19171c;
  --components-leftBar-background: #000;
  --components-modal-background: #19171c;
  --components-dropdown-background: #1f1c22;
  --components-dropdown-hover: #242027;
  --components-dropdown-active: #29252d;
  --grey-50: #f3f2f4;
}

.side-image {
  display: none;
  height: 100vh;

  @media screen and (min-width: 1100px) {
    display: flex !important;
    justify-content: center !important;
    position: relative;
    width: 50vw !important;
    height: 100vh;
    background-color: var(--background-200);
    padding: 40px;
    border-right: 0 !important;
  }
}

.login-panel {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  display: flex;
  background-color: var(--background-100);
  overflow: hidden;
  justify-content: center;
}

.forget-panel {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  display: flex;
  background-color: var(--background-100);
  overflow: hidden;
}

.login-form-container {
  width: 50vw;
  padding: 40px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  scrollbar-width: none;

  @media screen and (max-width: 800px) {
    width: 100vw;
  }
}

.login-form-container::-webkit-scrollbar {
  display: none !important;
}

.login-form {
  text-align: left !important;
  padding: 30px;
  width: 600px;
  justify-content: center;
  align-self: center;
}

.resend-otp {
  color: #1a63f4;
  font-size: 14px;
  cursor: pointer;
}

.back-btn {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.otp-input-field-item {
  height: 44px;
  width: 44px !important;
  border: 1px solid #d7cce4;
  background-color: #f5f4f6;
  margin-right: 10px;
  border-radius: 5px;
  transition-duration: 500ms;
  outline-color: #fff;
}

.otp-input-field-item::-webkit-inner-spin-button,
.otp-input-field-item::-webkit-outer-spin-button {
  appearance: none;

  /* margin: 0; Optional - if you want to remove the margin as well */
}

.otp-input-field-item:focus {
  outline-width: 2px;
  outline-color: #ddccef;
}

.horizontal-fields {
  display: flex;
  gap: 20px;
}

.password-field .ant-input {
  background-color: #f5f4f6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

.country-code-field .ant-select-selector {
  background-color: #f5f4f6 !important;
  border: 0 !important;
  height: 44px;
}

fieldset {
  border: none;
}

#footer {
  display: none;
}
